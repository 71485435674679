import { isEmpty, isNull } from 'lodash';
import React, {
  lazy,
  Suspense, useCallback, useEffect, useState,
} from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter, Route, Switch, Redirect, useHistory,
} from 'react-router-dom';
import {
  CssBaseline, ThemeProvider, createTheme,
} from '@material-ui/core';
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';

import {
  loginAction, logoutAction, signupAction,
  resumeLastSessionAction, acceptInvitationWithRegisterAction, wxloginAction,
} from '@ivymobi/orbit-core/business/user';
import configureStore from '@ivymobi/orbit-core/business/configureStore';
import { acceptInvitationWithLoginedSessionAction, getWechatAccessTokenByCode } from '@ivymobi/orbit-core/business/tenant';

import './services/i18n';
import * as api from './api';

import themeConfig from './services/theme';
import Loader from './components/loaders';
import Notification from './layout/notification';
import ErrorBoundary from './components/errorBoundles';

import User from './users';
import Invitations from './users/invitations';
import WechatCode from './users/wechatCode';
import { queryParse } from '@ivymobi/orbit-core';
import { InnerUserContext, UserContext } from './orbit-context';

// import ActiveUserContainer from './user';
// import Share from './shares';
const ActiveContainer = lazy(
  () => import('./mycontain' /* webpackChunkName: "user" */)
);
const Share = lazy(
  () => import('./shares' /* webpackChunkName: "share" */)
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: '100vh',
      // minWidth: 'fit-content',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      color: theme.palette.getContrastText(
        theme.palette.background.default
      ),
    },
  })
);

const Main = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const _innerUser = queryParse(window.location.search)
  const [innerUser, setInnerUser] = useState(_innerUser??{});

  const [user, setUser] = useState({});

  const doLogin = (userData) => {
    userData.logout = logout;
    setUser(userData);
  };

  const login = useCallback((loginData) => {
    dispatch(loginAction(loginData))
    .then(
      (userData) => {
        doLogin(userData);
        history.replace('/');
      },
      (err) => {
        console.error(err);
      }
    );
  }, [dispatch]);

  const signup = useCallback((signupData) => {
    dispatch(signupAction(signupData))
    .then(
      (userData) => {
        doLogin(userData);
        history.replace('/');
      },
      (err) => {
        console.error(err);
      });
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch(logoutAction())
    .then(() => {
      setUser({});
    });
  }, [dispatch, setUser]);

  useEffect(() => {
    // console.log('App启动后，自动上次的登录用户');

    dispatch(resumeLastSessionAction()).then(
      (userData) => {
        userData.logout = logout;
        setUser(userData);
      },
      () => setUser({}));
  }, []);

  return (
    <div className={classes.root}>
      <UserContext.Provider value={user}>
        <InnerUserContext.Provider value={innerUser}>

          <Switch>
            <ActiveContainer doLogin={doLogin} logout={logout} />
          </Switch>
        </InnerUserContext.Provider>
      </UserContext.Provider>
    </div>
  );
};

export default function OrbitApp() {
  const store = configureStore(api, {});
  const theme = createTheme(themeConfig);

  return (
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Suspense fallback={<Loader />} >
            <Main />
            <Notification />
          </Suspense>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
